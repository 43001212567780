<template>
  <div>

    <v-row dense>
      <v-col cols="3">
        <select-pos-model v-model="id_pos" :label="$t('labels.pos')" dense outlined clearable hide-details
          @change="onPosUpdate"></select-pos-model>
      </v-col>
      <v-col cols="3">
        <v-autocomplete v-model="stampSize" :items="sizeStampOptions" :label="$t('labels.stamp_size')" dense
          outlined class="c-input-small"></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <input-qr-scan-model v-model="code" ref="codeRef" :label="$t('labels.uid')" @keyupEnter="inputScan"
          :auto-close="false"></input-qr-scan-model>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "GenerateStampAgain",
  components: {

  },
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    id_pos: null,
    stampSize: '50x20',
    sizeStampOptions: [
      {
        text: '50x20',
        value: '50x20'
      },
      {
        text: '50x40',
        value: '50x40'
      },
    ]
  }),
  computed: {

  },
  mounted() {

  },
  methods: {
    onPosUpdate() {
      if (!this.id_pos) {
        return false
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput()
      })
    },
    async inputScan() {
      if (!this.code || !this.code.includes('@')) {
        this.$vToastify.error('Không đúng tem của SSC');
        this.$root.$emit('playErrorAudio')
      }
      const stampName = `${this.stampSize}_${this.code}.pdf`
      await this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-stamp-again`, {
        code: this.code,
        id_pos: this.id_pos,
        stamp_size: this.stampSize
      }, stampName)
      this.code = null
    },

  }
}
</script>

<style scoped></style>
